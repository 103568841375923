.projects{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 50px;
    font: 1.5rem 'Roboto', sans-serif;
    gap: 7vw;
    background-color: #ffffff;
}

.project-list{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 7vw;
}

.title{
    font-size: 5rem;
    font-weight: bold;
    color: #3A2278;
}
/* media smaller than 500px */
@media (max-width: 500px){
    .projects{
        padding: 30px;
        gap: 100px;
    }
    .project-list{
        margin-top: -10px;
    }
    .title{
        font-size: 2rem;
        margin-bottom: 0px;
    }
}