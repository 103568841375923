.project-card{
    all: unset;
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.715);

    /*border: 2px solid #390561;*/
    background-color: #280543;
    color: #FFFFFF;
    font-size: 1rem;
    transition: all 0.3s;
    user-select: none;
    cursor: pointer;
}

.project-card:hover{
    transform: translateY(-5px) scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.538);
}

.project-card:active{
    transform: translateY(0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 18px 18px 0 0;
    color: white;
    text-align: center;
    /*background-color: #390561;*/

    font-family: "Inria Sans", sans-serif;
    font-weight: 700;
    font-size: 1.4rem;
    font-style: normal;
}

.bg-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-color: white;
}

.overlay{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.description{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    font-family: "Inria Sans", sans-serif;
    font-weight: 600;
}

.tags{
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    gap: 5px;
    padding: 15px;
    font-size: 1rem;
    font-family: "Inria Sans", sans-serif;
    font-weight: 700;
}

.tag{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: 15px;
    white-space: nowrap;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 6px;
    padding-right: 8px;
    transition: 0.2s;
    cursor: default; 
}

@media (max-width: 425px){
    .project-card{
        width: 90%;
    }
}